import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

import CartView from 'components/Common/header/CartView';
import SearchModal from 'components/Common/SearchModal';
import { useUIContext } from 'components/context/uiContext/ManagedUiContext';
import { ROLE, staticMediaStoreBaseURL } from 'config/common';

const onClickOfCartIcon = ({
  setPageState,
  cartList = [],
  setShowCartSelectorModal,
  Router,
  isUserPlanner
}) => {
  const [{ id, isDefault } = {}] = cartList;
  const onlyDefaultCart = cartList.length === 1 && isDefault;
  if (onlyDefaultCart) {
    Router.push(`/${isUserPlanner ? 'planner-carts' : 'host-carts'}/${id}`);
  } else {
    setPageState('cart');
    setShowCartSelectorModal(cartList.length > 0);
  }
};

export default function MobileNavbar({
  onPage,
  showSearchModal,
  setShowSearchModal
}) {
  const Router = useRouter();
  const { role } = useUIContext();
  const isUserPlanner = role === ROLE.PLANNER;
  const [pageState, setPageState] = useState(onPage);
  const [showCartSelectorModal, setShowCartSelectorModal] = useState(false);

  useEffect(() => {
    setShowSearchModal(false);
  }, [Router.asPath]);

  const handleSearchClick = (e) => {
    e.preventDefault();
    setShowSearchModal(true);
  };

  const { cartItemCount, cartList, selectedCart } = useUIContext();

  return (
    <div className='fixed flex justify-between items-center bottom-0 z-10 h-16 w-full px-6 bg-white'>
      <Link href={'/'}>
        <div
          className='flex items-center flex-col cursor-pointer group'
          onClick={() => setPageState('home')}
        >
          <div
            className={`w-9 h-0.5 rounded-sm ${
              pageState === 'home' ? 'bg-brand-gradient' : 'bg-transparent'
            }`}
          ></div>
          <div className='py-3 flex items-center flex-col'>
            <div className='w-5.5 h-5.5 relative'>
              <Image
                src={
                  pageState === 'home'
                    ? `${staticMediaStoreBaseURL}/icons/home-color.svg`
                    : `${staticMediaStoreBaseURL}/icons/home-icon.svg`
                }
                alt='Home'
                height={22}
                width={22}
              />
            </div>

            <span
              className={`font-Inter text-10px font-normal hover:text-nero focus:text-nero group-hover:text-nero block leading-3 pt-1.5 ${
                pageState === 'home' ? 'text-nero' : 'text-silver'
              } `}
            >
              {' '}
              Home{' '}
            </span>
          </div>
        </div>
      </Link>

      <Link href={'/categories/list'}>
        <div
          className='flex items-center flex-col cursor-pointer group'
          onClick={() => setPageState('category-listing')}
        >
          <div
            className={`w-9 h-0.5 rounded-sm ${
              pageState === 'category-listing'
                ? 'bg-brand-gradient'
                : 'bg-transparent'
            }`}
          ></div>

          <div className='py-3 flex items-center flex-col'>
            <div className='w-5.5 h-5.5 relative'>
              <Image
                src={
                  pageState === 'category-listing'
                    ? `${staticMediaStoreBaseURL}/icons/cat-color.svg`
                    : `${staticMediaStoreBaseURL}/icons/cat-icon.svg`
                }
                alt='Categories'
                height={22}
                width={22}
              />
            </div>

            <span
              className={`font-Inter text-10px font-normal hover:text-nero focus:text-nero group-hover:text-nero block leading-3 pt-1.5 ${
                pageState === 'category-listing' ? 'text-nero' : 'text-silver'
              } `}
            >
              {' '}
              Categories{' '}
            </span>
          </div>
        </div>
      </Link>

      <div
        className='flex items-center flex-col cursor-pointer group'
        onClick={handleSearchClick}
      >
        <div
          className={`w-9 h-0.5 rounded-sm ${
            showSearchModal ? 'bg-brand-gradient' : 'bg-transparent'
          }`}
        ></div>

        <div className='py-3 flex items-center flex-col'>
          <div className='w-5.5 h-5.5 relative'>
            <Image
              src={
                showSearchModal
                  ? `${staticMediaStoreBaseURL}/icons/search-pink.svg`
                  : `${staticMediaStoreBaseURL}/icons/search-gray.svg`
              }
              alt='Search'
              height={22}
              width={22}
            />
          </div>
          <span
            className={`font-Inter text-10px font-normal hover:text-nero focus:text-nero group-hover:text-nero block leading-3 pt-1.5 ${
              pageState === 'search' ? 'text-nero' : 'text-silver'
            } `}
          >
            {' '}
            Explore{' '}
          </span>
        </div>
      </div>

      <div className='flex items-center flex-col cursor-pointer group'>
        <div
          className={`w-9 h-0.5 rounded-sm ${
            pageState === 'cart' ? 'bg-brand-gradient' : 'bg-transparent'
          }`}
        ></div>

        <div className='py-3 flex items-center flex-col relative'>
          <div className='w-5.5 h-5.5 relative'>
            {pageState === 'cart' ? (
              <CartView
                {...{
                  cartItemCount,
                  cartList,
                  Router,
                  selectedCart,
                  setShowCartSelectorModal,
                  showCartSelectorModal
                }}
              />
            ) : (
              <Image
                src={`${staticMediaStoreBaseURL}/icons/cart-gray.svg`}
                alt='Cart'
                layout='fill'
                height={22}
                width={22}
                onClick={() =>
                  onClickOfCartIcon({
                    setPageState,
                    setShowCartSelectorModal,
                    cartList,
                    Router,
                    isUserPlanner
                  })
                }
              />
            )}

            {cartItemCount > 0 && pageState !== 'cart' && (
              <span
                className={`absolute top-0 -right-3 flex items-center justify-center rounded-full h-4 w-4 text-white text-xxs leading-2 font-Inter font-normal ${
                  pageState === 'cart' ? 'bg-cart' : 'bg-dim-gray'
                }`}
              >
                {' '}
                {cartItemCount}{' '}
              </span>
            )}
          </div>

          <span
            className={`font-Inter text-10px font-normal hover:text-nero focus:text-nero group-hover:text-nero block leading-3 pt-1.5 ${
              pageState === 'cart' ? 'text-nero md:pl-2' : 'text-silver'
            } `}
          >
            {' '}
            Cart{' '}
          </span>
        </div>
      </div>

      <Link href={'/user-profile'}>
        <div
          className='flex items-center flex-col cursor-pointer group'
          onClick={() => setPageState('user-profile')}
        >
          <div
            className={`w-9 h-0.5 rounded-sm ${
              pageState === 'user-profile'
                ? 'bg-brand-gradient'
                : 'bg-transparent'
            }`}
          ></div>

          <div className='py-3 flex items-center flex-col'>
            <div className='w-5.5 h-5.5 relative'>
              <Image
                src={
                  pageState === 'user-profile'
                    ? `${staticMediaStoreBaseURL}/icons/person-round-color.svg`
                    : `${staticMediaStoreBaseURL}/icons/person-round.svg`
                }
                alt='User Profile'
                layout='fill'
                height={22}
                width={22}
              />
            </div>

            <span
              className={`font-Inter text-10px font-normal hover:text-nero focus:text-nero group-hover:text-nero block leading-3 pt-1.5 ${
                pageState === 'user-profile' ? 'text-nero' : 'text-silver'
              } `}
            >
              {' '}
              Profile{' '}
            </span>
          </div>
        </div>
      </Link>

      {showSearchModal ? (
        <SearchModal setShowSearchModal={setShowSearchModal} />
      ) : (
        ''
      )}
    </div>
  );
}
